@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.lineproductiongrid {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
}

.lineproductionparent{
    display: grid;
    place-items: center;
}

.lineproductiontable {
    border: white 1px solid;
    border-radius: 8px 0px 0px 8px;
    display: grid;
    justify-content: start;
    align-items: center;
    padding: 3px 7px;
    font-weight: 500;
    font-size: 15px;
}

.lineproductiontable2 {
    border: white 1px solid;
    border-radius: 0px 8px 8px 0px;
    display: grid;
    justify-content: end;
    align-items: center;
    padding: 3px 7px;
    font-weight: 400;
    border-left: 0px;
    font-size: 18px;
}

.lineproductiondate {
    background-color: white;
    height: 42px;
    width: 100%;
    border-radius: 8px;
    padding: 0px 10px;
}

.lineproductiongrid2 {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.lineproductionoptions {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;   
}

@media (max-width: 767.50px) {
    .lineproductiongridmodel {
      display: none;
    }
  }
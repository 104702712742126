@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@500&family=Inter:wght@500&display=swap');

.lineproductiontablecontainer {
   background-color: white;
   display: grid;
   place-items: center;
   border-radius: 12px;
}

.lineproductiontablegrid {
    background-color: #ccdfe7;
    display: grid;
    justify-content: center;
    align-items: center;
    /* font-family: 'Inknut Antiqua', serif; */
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    border-radius: 12px 12px 0 0;
    color: black;
    padding: 5px 0;
}

.lineproductiontablegrid0{
    padding: 0px 16px;
}

.lineproductiontablegrid1, .lineproductiontablegrid2 {
    color: black;
    border: 1px solid grey;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    display: grid;
    align-items: center;
}

.lineproductiontablegrid1 {
    padding: 5px 0px 5px 8px;
}

.lineproductiontablegrid2 {
    border-left: 0;
    text-align: end;
    padding-right: 8px;
}

.lineproductiontablecharts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

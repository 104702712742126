/* .data-list-container {
    margin: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .data-table th {
    background-color: #f2f2f2;
  }
  

   */


   .data-list-container {
    margin: 20px;
    overflow-x: auto; /* Add horizontal scroll for small screens */
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .data-table th {
   
    background-color: #1A73E8;
    color: white;
    font-weight: 500 ;
  }
  .custom-dropdown {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
  }
  
  .custom-dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  
  @media (max-width: 768px) {
    /* Adjust styling for smaller screens */
    .data-table {
      overflow: auto;
      display: block;
    }
  
    .data-table thead,
    .data-table tbody,
    .data-table th,
    .data-table td,
    .data-table tr {
      display: block;
    }
  
    .data-table th {
      position: absolute;
      top: -9999px;
      left: -9999px;
      display: none;
    }
  
    .data-table tr {
      margin-bottom: 15px;
    }
  
    .data-table td {
      border: none;
      border-bottom: 1px solid #ddd;
      position: relative;
      padding-left: 50%;
      text-align: left;
    }
  
    .data-table td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  
    .data-table td:nth-of-type(1):before {
      content: 'Name: ';
    }
  
    .data-table td:nth-of-type(2):before {
      content: 'Parameter Name: ';
    }
  
    .data-table td:nth-of-type(3):before {
      content: 'Parameter Value: ';
    }
  
    .data-table td:nth-of-type(4):before {
      content: 'Remark: ';
    }
  
    .data-table td:nth-of-type(5):before {
      content: 'Ok/Not Ok: ';
    }
  }
  
  .card-styling {
    border: 1px solid #eee;
    border-radius: 15px;
    padding: 20px 0px 20px 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.5);
    max-width: 80%;
  }

  .img-placeholder {
    display: grid;
    justify-content: center;
    max-height: 200px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 16px -6px rgba(0, 0, 0, 0.75);
  }
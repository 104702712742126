.form-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 600px; /* Set a maximum width for the form container */
  width: 100%; /* Make sure it takes 100% width of its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow: hidden;
  margin-top: 3px;
}

.form-container label,
.form-container select {
  display: block;
  margin-bottom: 10px;
}

.form-container input,
.form-container select {
  width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container .error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}



/* Media query for smaller screens */
@media (max-width: 600px) {
  .form-container {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .form-container button[type="submit"] {
    padding: 8px 15px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

.search-bar {
  margin-top: 5px;
  margin-bottom: 10px;
}

.data-table2 {
  border-collapse: collapse;
  width: 100%;
}

.data-table2 th,
.data-table2 td {

  border-bottom: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  font-size: 12px;
}

.data-table2 th {
  color: rgb(123, 128, 154);
}

.total-pages2 {
  margin: 6px;
  font-size: 15px;
  font-weight: bold;
  color: rgb(123, 128, 154);

}

.previous-button,
.next-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.previous-button:hover,
.next-button:hover {
  background-color: #45a049;
}

.previous-button:disabled,
.next-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.md_button {
  margin: 30px;
}

.pagination-buttons2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.selected-items {
  margin-top: 20px;
}

.selected-items p {
  font-weight: bold;
}

.selected-items ul {
  list-style: none;
  padding: 0;
}

.selected-items li {
  margin-bottom: 5px;
}

.data-table2 input[type="checkbox"] {
  margin-right: 5px;
}



